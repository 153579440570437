@use "./styles/responsive.scss";

.main {
  display: flex;
  align-items: center;
  transform: translateY(calc(50vh - 100px));

  > * {
    flex: 1 1 0;
    padding: 0 10%;
  }
}

.logo {
  border-left: 1px solid #ddd;
  height: 200px;
}

.logoText {
  margin-bottom: 20px;
}

@include responsive.mq($until: mobile) {
  .main {
    transform: translateY(100px);
    text-align: center;
    flex-wrap: wrap;
  }

  .logo {
    margin-top: 50px;
  }
}
